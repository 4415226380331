.breadcrumb {
  display: flex;
  align-items: center;
  margin: 0;
  color: $grey;
  font-weight: 500;
  
  &__item {
    margin: 10px;
    
    &--selected {
      color: $black;
    }
  }

  svg {
    width: 16px;
    transform: rotate(-90deg);
  }

  &__item:hover {
    color: $primary-1;
  }

  &__item:first-child {
    margin-left: 0;
  }
}

@media (max-width: $breakpoint-tablet) {
  .breadcrumb {
    display: none;
  }
}