.home {
  &__section {
    padding: 6rem 0;

    &:nth-child(even) {
      background-color: $background-lightest-grey;
    }
  }

  &__section-header {
    text-align: center;

    p {
      color: $primary-1;
      font-weight: 500;
      letter-spacing: 2px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 4rem;
    text-align: center;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -3rem auto 5rem;
    color: $primary-1;
    font-weight: 700;
  
    svg {
      width: 16px;
      height: 16px;
      margin-left: 0.5rem;
      transform: rotate(-90deg);
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .home {
    &__title {
      margin: 4px 0 26px;
    }

    &__section {
      padding: 24px 0;
    }

    &__link {
      margin: 2rem auto;
    }
  }
}

.home__section--community {
  .container__col--3 {
    @media screen and (max-width: $breakpoint-mobile-large) {
      grid-template-columns: 1fr;
    }
  }
}
