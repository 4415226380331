.donate-section-one {
  max-width: 900px;
  margin-bottom: 96px;
  font-family: $open-sans;

  @media (max-width: $breakpoint-tablet) {
    margin: 0 30px;
  }

  &__title {
    margin-bottom: 32px;
  }

  &__body {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @media (max-width: $breakpoint-tablet-large) {
      flex-direction: column-reverse;
    }
  }

  &__content {
    @media (max-width: $breakpoint-mobile) {
      text-align: center;
    }
  }

  &__text {
    margin-bottom: 32px;
    line-height: 1.8;

    @media (max-width: $breakpoint-mobile-large) {
      text-align: left;
    }
  }

  &__image {
    object-fit: cover;
    width: 280px;
    height: 100%;
    margin-right: 36px;

    @media (max-width: $breakpoint-tablet-large) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 32px;
    }

    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      height: auto;
    }
  }
}
