$max_event_content_width: 800px;

.event {
  margin-top: 2rem;

  &__title {
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  &__image {
    display: block;
    object-fit: cover;
    width: 100%;
    max-width: 600px;
    max-height: 500px;
    margin: 3rem 0;
  }

  .hero__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  .hero { 
    max-width: 600px;
    height: 20rem;
    margin: 3rem 0;
  }

  .hero__wrapper {
    background-color: $background-lightest-grey;
  }

  &__date, &__location {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;

    svg { 
      width: 20px;
      margin-right: 0.5rem;
      color: $grey;
    }
  }

  &__location {
    margin-top: 0.5rem;
  }

  &__spacer {
    margin: auto 0.4rem;
  }

  h4 {
    margin-top: 3rem;
    font-weight: 700;
  }

  &__details {
    padding: 1rem 2rem;
    background: $background-lightest-grey;
    line-height: 1.8;

    p { 
      margin-bottom: 2rem;
    }

    .button {
      display: block; 
      width: fit-content; 
      margin: 1rem 0;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .event {
    display: block;
    margin: 0 auto 48px;

    &__details { 
      padding: 1rem;
    }
  }
}
