.events {
  &__subtitle {
    margin-top: 1rem;
    font-size: 24px;

    &--annual {
      margin-top: 4rem;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 2rem;
    grid-column-gap: 2rem;
  }
}

.card-event {
  min-width: 30%;
}

@media screen and (max-width: $breakpoint-tablet){
  .events {
    &__cards {
      grid-template-columns: 1fr;
    }
  }
}
