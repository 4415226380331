.performing-arts {
  &__container {
    margin-bottom: 5rem;
    
    @media screen and (max-width: $breakpoint-laptop) {
      padding: 0 1rem;
    }  
  }

  &__contact { 
    margin-bottom: 1.5rem;
    * {
      margin: 4px 0;
    }
  }

  &__description {
    margin-bottom: 3rem;
  }
}
  
