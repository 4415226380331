// Official Figma Colors
$primary-1: #4F2A80; // Darkest-Purple & Dark-Purple
$primary-2: #A183CC; // Purple
$primary-3: #E2D8EF; // Light-Purple
$primary-4: #F5F0FC; // Lighter-Purple
$primary-5: #285F1A; // Dark-Green

// Default Colors
$white: #FFFFFF;
$black: #242728;
$dark-grey: #494949;
$grey: #646668;
$lighter-grey: #F7F7F7;
$light-grey: #D3D3D3;
$background-lightest-grey: #FBFBFB;
$danger: #FF0000;
$error: #B80202;

// Box Shadows
$card-blank-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14),
  0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2);
$card-blank-shadow-mobile: 0px 4px 5px rgba(0, 0, 0, 0.12),
  0px 2px 1px rgba(0, 0, 0, 0.14);
$card-large-shadow: 0px 2px 1px rgba(0, 0, 0, 0.14),
  0px 4px 5px rgba(0, 0, 0, 0.12);
$card-small-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14),
  0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2);
$card-square-shadow: 0px 2px 1px rgba(0, 0, 0, 0.14),
  0px 4px 5px rgba(0, 0, 0, 0.12);
$card-event-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
$navbar-container-shadow: drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.25));
$navbar-dropdown-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
$sidebar-card-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);

// Overlays
$overlay-light-grey: rgba(247, 249, 252, 0.9);
$overlay-darker-grey: rgba(80, 68, 68, 0.35);
