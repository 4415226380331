.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  padding: 1rem;
  text-align: center;

  &__title {
    margin: 1rem 0;
    margin-bottom: 0;
    color: $light-grey;
    font-size: 5rem;

    @media screen and (max-width: $breakpoint-mobile-large) {
      font-size: 3rem;
    }
  }

  &__description {
    max-width: 70ch;
    margin-top: 1rem;
    font-size: 1rem;
  }
}
