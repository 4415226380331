.sidebar {
  @media (max-width: $breakpoint-laptop) {
    display: none;
  }

  &__card {
    background-color: white;
    margin: 1.5rem auto;
    border-radius: 5px;
    padding: 1.5rem;
    padding-bottom: 0.5rem;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);

    &--center {
      margin-top: 3rem;
      padding-bottom: 2rem;

      .group:not(:last-child) {
        margin-bottom: 3rem;
      }

      h3 {
        margin: 0;
        border-bottom: 3px solid $black;
        font-weight: 700;
      }

      p {
        margin: 1rem 0 0.5rem 0;
        opacity: 0.5;
      }
    }
  }

  &__icon {
    width: auto;
    height: 55px;
  }

  &__link {
    position: relative;
    display: block;
    padding: 0.6rem 0;
    color: $grey;
    font-weight: 500;

    &:not(:last-child) {
      border-bottom: 1px solid $light-grey;
    }

    &:hover {
      color: $black;
      font-weight: 600;
    }

    &[data-active='true'] {
      border-radius: 3px;
      padding-left: 1rem;
      color: $primary-1;
      font-weight: 600;

      &::before {
        content: '';
        position: absolute;
        bottom: 20%;
        left: 0;
        width: 6px;
        height: 60%;
        border-radius: 5px;
        background-color: $primary-1;
      }
    }
  }

  &__parent {
    position: relative;
    margin-bottom: 0.8rem;
    border-bottom: 3px solid $black;
    padding-bottom: 0.5rem;
    font-weight: bolder;
    font-size: 18px;
  }

  &__newsletters {
    display: flex;
    align-items: center;
    margin: 1rem 0;

    svg {
      margin-right: 1rem;
    }

    p {
      margin: 0;
      opacity: 1;
    }
  }

  &__button {
    color: $primary-1;
    font-weight: 600;
  }
}
