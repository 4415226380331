body, html {
  font-family: $poppins;
  scroll-behavior: smooth;
}

h2 {
  font-size: 42px;
  font-family: $lora;
  line-height: 1.25;
}

h3 {
  font-weight: 300;
  font-size: 18px;
  font-family: $poppins;
  line-height: 37px;
}

h4 {
  font-weight: 600;
  font-size: 24px;
  font-family: $poppins;
  line-height: 36px;
  text-transform: capitalize;
}

h5 {
  font-weight: 300;
  font-size: 13px;
  font-family: $poppins;
  line-height: 24px;
}

p, li {
  line-height: 1.8;
  font-family: $open-sans;
}

.p1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  font-family: $poppins
}

a {
  color: inherit;
  text-decoration: none;
}

a:not([class]):not([href^="/"]):not([data-text="Shop"]) {
  position: relative;
  font-weight: 500;
  text-decoration: underline;
  color: $primary-1;
  
  svg{
    text-decoration: none;
    color: $black;
  }
}

li + li {
  padding-top: 12px;
}

@media screen and (max-width: $breakpoint-tablet) {
  h2 {
    font-size: 28px;
  }

  .p1{
    font-size: 20px;
    line-height: 30px;
  }

  li + li {
    padding-top: 32px;
  }
}
