$card-image-height: 330px;

.card-large {
  display: grid;
  grid-template-areas: 'image' 'text' 'button';
  grid-template-rows: $card-image-height auto auto;
  justify-self: center;
  overflow: hidden;
  max-width: 600px;
  padding: 0;
}

.card-large__image {
  grid-area: image;
  object-fit: cover;
  width: 100%;
  height: $card-image-height;
  margin: 0 auto;
}

.card-large__text {
  grid-area: text;
  padding-top: 20px;
  font-weight: normal;
  font-style: normal;
  font-family: Poppins;
}

.card-large__title {
  margin: 1rem 0 0.5rem 0;
  font-weight: 800;
  line-height: 1.4;
}

.card-large__body {
  margin: 0;
  padding-bottom: 1rem;
  color: $grey;
  font-size: 16px;
  line-height: 1.6;
}

.card-large__button {
  grid-area: button;
  grid-row: 50px;
  height: 45px;
  margin-bottom: 35px;
}

@media (max-width: $breakpoint-mobile) {
  .card-large {
    grid-template-rows: 230px auto 74px;
    margin: 16px 0;

    &__body {
      padding-bottom: 0;
      font-size: 16px;
    }

    &__title {
      margin: 0 0 16px;
    }

    &__image {
      height: 100%;
    }

    &__button {
      margin-top: 1rem;
    }
  }

  .card-large__body {
    text-align: left;
  }
}
