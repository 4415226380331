@media (min-width: $breakpoint-laptop) {
  .layout {
    display: grid;
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
    max-width: 1200px;
    margin: 4rem auto;
    padding: 0 1.5rem;
    column-gap: 12px;
  }

  .layout__sidenav {
    grid-column-start: 1;
    grid-column-end: 1;
  }

  .layout__body {
    grid-column-start: 2;
    grid-column-end: 5;
    margin-left: 2rem;
  }

  .layout__full {
    grid-column-start: 1;
    grid-column-end: 5;
  }
}
