.text-area {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  max-width: 1200px;
  margin: auto;
  padding: 6rem 10rem;
  background-color: $background-lightest-grey;
  text-align: left;

  &__subtitle {
    color: $primary-1;
    font-weight: 500;
    letter-spacing: 2px;
  }

  &__title {
    max-width: 500px;
    margin: 0.5rem 0;
    line-height: 1.4;
    text-align: left;

    @media (max-width: $breakpoint-mobile-large) {
      font-size: 28px;
    }
  }

  &__body {
    max-width: 60ch;
    font-size: 16px;

    @media (max-width: $breakpoint-mobile-large) {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile-large) {
  .text-area {
    margin: 28px 0;
    padding: 2rem 1rem 3rem;
    text-align: center;

    &__title {
      font-size: 28px;
      text-align: center;
    }

    &__body {
      font-size: 16px;
    }
  }
}
