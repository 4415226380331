/* Align Classes */

.align-stretch {
  align-items: stretch;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.justify-items-stretch {
  justify-items: stretch;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-self-stretch {
  justify-items: stretch;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}
