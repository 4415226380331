.card-newsletter {
  justify-self: center;
  min-width: 280px;
  max-width: 536px;
  height: fit-content;
  border-radius: $card-border-radius;
  padding: 30px;
  background-color: $background-lightest-grey;
  text-align: left;

  @media (max-width: $breakpoint-tablet) {
    padding: 16px;
  }

  &__title {
    margin: 0;
    font-weight: 700;
    font-style: normal;
    font-size: 21px;
  }

  &__body {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
  }

  &__button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
  }

  &__link {
    display: flex;
    align-items: center;
    width: auto;
    margin-right: 2rem;
    color: $primary-1;
    font-weight: 600;

    svg {
      margin-right: 0.5rem;
    }
  }
}
