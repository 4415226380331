.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__overlay {
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: 0.7;
  }

  &__container {
    position: absolute;
    padding: 48px;
    background-color: $white;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;

    &:hover {
      cursor: pointer;
    }
  }
}
