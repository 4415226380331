.card-blast {
  min-width: 280px;
  max-width: 536px;
  background-color: $background-lightest-grey;
  padding: 2rem;
  border-radius: $card-border-radius;

  @media (max-width: $breakpoint-tablet) {
    justify-self: center;
  }

  &__title {
    margin: 0;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
  }

  &__body {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
  }

  &__form {
    margin-top: 2rem;

    @media (max-width: $breakpoint-mobile) {
      padding: 1rem;
    }

    &__name-container {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        gap: 0;
      }
    }

    &__input-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 15px;
    }
  }

  &__other-fields {
    overflow: hidden;
    transition: 2s all ease-out;
  }

  &__label {
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 14px;
  }

  &__input {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid $light-grey;
    border-style: solid;
    border-radius: 5px;
    padding: 10px 20px;
    background-color: $lighter-grey;

    &::placeholder {
      opacity: 0;
    }

    &__error-message {
      display: none;
      margin: 0;
      color: $error;
      font-weight: bold;
    }

    &:not(:focus):not(:placeholder-shown):invalid {
      border-color: $error;
    }

    &:not(:focus):not(:placeholder-shown):invalid ~ .card-blast__error-message {
      display: block;
    }

    &:not(:focus):not(:placeholder-shown):valid {
      border-color: $primary-5;
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    padding: 1rem 0;

    input {
      width: 100%;
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-style: normal;
  }

  &__success__header-container {
    display: flex;
  }

  &__success__header-icon {
    align-self: center;
    width: 35px;
    height: 35px;
    margin-right: 1em;
  }

  &__success__header-title {
    font-weight: 500;
    font-size: 21px;
    line-height: 24px;
  }

  &__success__body {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__success__notice {
    border-left: 4px solid gray;
    padding-left: 8px;
  }
}
