.card-blank {
  min-width: 280px;
  border-radius: $card-border-radius;
  background: $white;
  box-shadow: $card-blank-shadow;
  text-align: center;

  &__subscribe {
    padding: 50px 80px;
    text-align: left;
  
    .button {
      width: 100%;
    }
  }
  
  &__newsletter {
    padding: 50px 80px;
    text-align: left;
  
    p {
      margin-bottom: 24px;
    }
  }
  
  &__input {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 15px;
    border-style: solid;
    border-radius: 4px;
    padding: 10px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .card {
    margin: 1rem;
    box-shadow: $card-blank-shadow-mobile;

    &-blank {
      &__newsletter,
      &__subscribe {
        padding: 16px;

        h3 {
          margin-top: 0;
          text-align: center;
        }
      }
    }
  }
}
