.banquet-facility {
  display: grid;
  margin-top: 24px;
  margin-bottom: 15rem;
}

@media screen and (max-width: $breakpoint-tablet) {
  .banquet-facility {
    margin-bottom: 4rem;
  }
}
