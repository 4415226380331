.news {
  overflow: hidden;
  margin-bottom: 3rem;

  &__mobile-select {
    display: none;
    width: 200px;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background: url(../../assets/chevron-down.svg) no-repeat right;
    -webkit-appearance: none;
    background-position-x: 95%;
    background-size: 16px;
    font-weight: bold;
    cursor: pointer;

    @media (max-width: $breakpoint-mobile) {
      display: block;
      margin: auto;
    }
  }

  &__desktop-select {
    display: flex;
    border-bottom: 1px solid $light-grey;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }

  &__tab {
    border: none;
    padding: 0.75rem;
    background-color: $white;
    color: $grey;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.4s background ease;

    &:hover {
      background-color: $lighter-grey;
    }

    &--active {
      background-color: $primary-4;
      color: $primary-1;
      font-weight: 600;

      &:hover {
        background-color: $primary-4;
      }
    }
  }

  &__feed {
    position: relative;
    min-height: 5rem;
    padding-top: 2rem;
    background-color: $background-lightest-grey;
  }

  .watermark-cover {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 40px;
    background: $white;
  }

  .card-blast {
    margin: 3rem 0;
    width: 100%;
  }
}