.resources {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 2rem auto;

  &__link {
    background-color: white;
    position: relative;
    overflow: hidden;
    width: 40%;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border: 1px solid $light-grey;
    border-radius: $card-border-radius;
    padding: 1.5rem 2rem;
    transition: 0.4s ease;

    @media screen and (max-width: $breakpoint-tablet-large) {
      width: 100%;
      margin: 0.5rem auto;
    }

    &:hover {
      background-color: $primary-1;
      color: $white;

      p {
        color: $white;
      }

     svg {
        background-color: $primary-1;
      }
    }
  }

  &__description {
    p {
      margin: 0.5rem 0;
      word-wrap: break-word;
      opacity: 0.7;
    }
  }

  &__external {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    padding: 1rem;
    background-color: $lighter-grey;
    transition: 0.2s all ease;
  }
}
