.card-event {
  position: relative;
  margin-bottom: 2rem;

  &__image-and-title {
    position: relative;
    display: flex;
    height: 230px;
    margin-bottom: 1.5rem;
    background-image: linear-gradient(to top, $black 0%, transparent 80%);
  }

  &__title {
    align-self: flex-end;
    padding: 0 1rem;
    color: $white;
    font-weight: 600;
    font-size: 18px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-color: $light-grey;
  }

  &__full-date {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    svg { 
      width: 20px;
      margin-right: 0.5rem;
      color: $black;
    }
  }

  &__location-container {
    display: flex;

    svg { 
      flex-shrink: 0;
      width: 20px;
      margin: 0;
      margin-right: 0.5rem;
    }
  }

  &__location {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a:not(:last-child) { 
      margin-bottom: -1rem;
    }
  }

  &__spacer {
    margin: auto 0.5rem;
  }

  &__button {
    .button {
      margin-top: 0.5rem;
      padding: 0;     
      font-weight: 700;
      letter-spacing: 0; 
      text-transform: none;
      
      &:hover { 
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet){
  .card-event {
    margin-bottom: 24px
  }
}
