.history-of-huoa {
  &__section:not(:last-child) {
    margin-bottom: 5rem;
  }

  p {
    margin-bottom: 2rem;
  }

  strong { 
    display: inline-block;
     margin-bottom: 0.5rem;
  }

  h4 {
    margin-bottom: 1rem;
    font-weight: 700;
  }

  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;

    img {
      max-height: 50vh;
      margin: 1rem 0;
    }

    p {
      margin: 0;
      color: $grey;
      text-align: center;
    }

    strong { 
      text-transform: uppercase;
    }
  }
}
