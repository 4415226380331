.facebook-feed {
  display: flex;
  flex-direction: column;
  align-items: center;

  iframe {
    max-width: 500px;
    margin-top: 1rem;
  }
}
