.hawaii-okinawa-plaza {
  margin-bottom: 3rem;

  .hero {
    overflow: visible;
    margin-bottom: 5rem;

    picture,
    .hero__image {
      width: 100%;
      height: 100%;
    }
  }

  &__timeline {
    margin: 2rem 0;

    strong {
      position: absolute;
      top: -0.5rem;
      font-size: 1.2rem;
    }

    p {
      position: relative;
      margin: 0;
      padding-bottom: 3rem;
      padding-left: 2rem;

      @media (max-width: $breakpoint-mobile-large) {
        padding-left: 1rem;
      }

      &:not(:last-child) {
        border-left: 1px dashed $light-grey;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -1rem;
        width: 8px;
        height: 8px;
        border: 1px solid $grey;
        border-radius: 50%;
        background-color: $white;
        transform: translateX(calc(50% + 6px));
      }
    }
  }
}
