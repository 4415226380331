.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $primary-1;
  border-radius: 120px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 16px;
  font-family: $poppins;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition-timing-function: ease-in-out;
  transition-duration: 0.4s;
  transition-property: background-color, color, border-color, fill;

  &[type=filled],
  &[type=submit] {
    background-color: $primary-1;
    color: $white;
    fill: $white;

    &:hover {
      border-color: $primary-2;
      background-color: $primary-2;
    }

    &:disabled {
      border-color: $lighter-grey;
      background: $lighter-grey;
    }
  }

  &[type=outlined] {
    background: $white;
    color: $primary-1;
    fill: $primary-1;

    &:focus {
      border-color: $primary-1;
      color: $primary-2;
    }

    &:hover {
      color: $white;
    }

    &:disabled {
      border-color: $grey;
      background: $white;
      color: $dark-grey;
    }
  }

  &[type=text] {
    border-color: transparent;
    background: transparent;
    color: $primary-1;
    font-weight: 600;
    fill: $primary-1;

    &:focus {
      border-color: transparent;
      background: rgba(179, 112, 160, 0.54);
      color: $primary-1;
    }

    &:hover {
      border-color: transparent;
      background: $primary-3;
      color: $primary-1;
      fill: $primary-1;
    }

    &:disabled {
      background: none;
      color: $dark-grey;
    }
  }

  &:hover {
    border-color: $primary-1;
    background: $primary-1;
    color: $white;
    fill: $white;
  }

  &:focus {
    border-color: $primary-2;
    outline: none;
  }

  &:disabled {
    color: $dark-grey;
    fill: $dark-grey;
  }

  &__plus-icon {
    position: relative;
    top: 2px;
    width: 16px;
    height: 16px;
    padding-right: 4px;
  }
}
