.donate-section-two {
  margin: 64px auto 152px 0;

  @media (max-width: $breakpoint-tablet) {
    margin: 64px 30px 48px;
  }
  
  &__title {
    color: $black;
    font-weight: 700;
    font-size: 32px;

    @media (max-width: $breakpoint-mobile-large) {
      margin-bottom: 32px;
      text-align: center;
    }
  }

  &__body {
    display: flex;
    align-items: stretch;
    justify-content: center;

    @media(max-width: $breakpoint-mobile-large) {
      flex-direction: column;
      align-items: center;
      max-width: 500px;
      margin: auto;
    }
  }

  &__col {
    box-sizing: border-box;
    width: 100%;
    padding: 2rem 1rem;
    background: $white;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    text-align: center;

    @media(max-width: $breakpoint-mobile-large) {
    margin: 1rem auto;
    }

    @media (max-width: $breakpoint-mobile) {
      margin: 0 auto 32px;
    }

    &:not(:last-child){
      margin-right: 1rem;
    }
  }

  &__icon-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    border-radius: 50%;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__subtitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  &__text {
    color: $grey;
    font-size: 14px;
    line-height: 1.8;
  }
}
