
.accordion {
  width: 100%;
  // max-width: 740px;
}

.panel {
  border: 1px solid $light-grey;
  border-radius: $card-border-radius;
  padding: 0 1rem;
  background: $lighter-grey;

  h4 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }

  strong {
    text-transform: capitalize;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.panel__label {
  position: relative;
  display: block;
  width: 100%;
  border: none;
  padding: 25px 60px 25px 25px;
  background: none;
  font-weight: 500;
  font-size: 17px;
  font-family: inherit;
  text-align: left;
  cursor: pointer;
  transition: color 0.2s linear;

  &:focus {
    outline: none;
  }

  &:before {
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
    transform: rotate(-90deg);
  }
}

.panel__label:after,
.panel__label:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 25px;
  width: 12px;
  height: 2px;
  margin-top: -2px;
  background-color: $primary-1;
}

.panel[aria-expanded='true'] {
  .panel__content {
    opacity: 1;
  }

  .panel__label {
    color: $primary-1;

    &:before {
      transform: rotate(0deg);
    }
  }
}

.panel__inner {
  overflow: hidden;
  will-change: height;
  transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.panel__content {
  margin: 5px 25px 25px;
  margin-bottom: 2rem;
  color: $black;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s linear 0.18s;

  &:not(:first-child){
    border-top: 1px dashed $light-grey;
  }
  
  a {
    text-decoration-line: underline;
    color: $primary-1;
    font-weight: 500;
    
    &:hover {
      font-weight: 800;
    }
  }

}
