.hawaii-okinawa-center {
  &__section:not(:last-child) {
    margin-bottom: 6rem;
  }

  p { 
    margin-bottom: 2rem;
  }

  strong { 
    display: inline-block;
     margin-bottom: 0.5rem;
  }

  h4 { 
    margin-bottom: 1rem;
    font-weight: 700;
  }

  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;

    img {
      max-width: 100%;
      max-height: 70vh;
      margin: 1rem 0;
    }

    strong {
      margin-top: 1rem;
      text-transform: uppercase;
    }

    p {
      margin: 0;
      color: $grey;
      text-align: center;
    }
  }
}
