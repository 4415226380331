.navbar {
  &__container {
    position: relative;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    background-color: $white;
 
    @media (max-width: $breakpoint-mobile-large) {
      display: none;
    }
  }

  &__container--sm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $white;
  }

  &__first-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    max-width: 1264px;
    padding: 1rem 2rem;

    .button {
      margin-left: 1rem;
    }
  }

  &__logo-box {
    display: flex;
    align-items: center;
  }

  &__first-row-links {
    display: flex;
    align-items: center;

    .navbar__link{
      margin: auto 1rem;
      padding-left: 2rem;
    }
  }
  
  &__first-row-link {
    position: relative;
    flex-direction: row;
    font-weight: 500;

    &:hover {
      color: $primary-1;
      font-weight: 700;
    }

    svg {
      position: absolute;
      left: 1rem;
      width: 20px;
    }
  }

  &__logo {
    width: 5rem;
    height: 5rem;
    margin-left: -0.5rem;
    fill: $primary-1;
  }

  &__title {
    margin: 0;
    padding: 0;
    color: $dark-grey;
    font-size: 1.3rem;

    @media (max-width: $breakpoint-tablet-large) {
      margin-right: 6rem;
    }

    &--sm {
      margin: 0;
      padding: 0;
      color: $black;
      font-size: 0.8rem;
    }
  }

  &__second-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    padding: 0.75rem 0;
    background-color: $background-lightest-grey;
    list-style: none;

    .arrow-down {
      position: absolute;
      right: -1.5rem;
      width: 16px;
      color: $grey;
    }
  }

  &__titles {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
  }

  &__link {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    margin: auto 2rem;

    color: $black;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;

    svg {
      margin-right: 0;
    }

    &--active {
      color: $primary-1;
      font-weight: 700;
    }

    &:hover {
      color: $primary-1;
      font-weight: 700;
    }

    &::after {
      content: attr(data-text);
      overflow: hidden;
      height: 0;
      font-weight: 700;
      visibility: hidden;
      pointer-events: none;
      user-select: none;

      @media speech {
        display: none;
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 10rem;
    z-index: 999;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.09);
    background-color: $white;
    filter: $navbar-dropdown-shadow;

    &--link {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      padding: 0.5rem 1rem;
      color: $black;
      font-weight: 100;
      font-size: 1rem;
      font-family: $poppins;

      &:not(:last-child) {
        border-bottom: 1px solid $light-grey;
      }

      &:hover {
        color: $primary-1;
        font-weight: 900;
      }

      &::after {
        content: attr(data-text);
        content: attr(data-text) / '';
        overflow: hidden;
        height: 0;
        font-weight: 900;
        visibility: hidden;
        pointer-events: none;
        user-select: none;

        @media speech {
          display: none;
        }
      }
    }

    &--links {
      padding: 0;
      list-style-type: none;
    }
  }
}
