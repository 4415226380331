.about-mission-purpose {
  &__section {
    margin-bottom: 3rem;

    &:not(:first-child) {
      h2 {
        margin-bottom: 1rem;
        font-size: 28px;
        font-family: $poppins;
      }
    }
  }

  h4 {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
  }

  ul {
    border: 1px solid $light-grey;
    border-radius: 5px;
    padding: 2rem 3rem;
    background-color: $background-lightest-grey;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .about-mission-purpose {
    margin: 1rem;

    h4 {
      text-indent: 0px;
    }
  }
}
